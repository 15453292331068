import React from "react"
import { DateTime } from "luxon"
import { Line } from "react-chartjs-2"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import { StaticImage } from "gatsby-plugin-image"

export default class GrowthCalculator extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    customers: 0,
    newCustomers: 0,
    churn: 5,
    arpa: 0,
    maxCustomers: "",
    maxRevenue: "",
    customerWall: "",
    growthEndDate: "",
    growthWallDate: "",
    endOfGrowth: "",
    labels: [],
    datasets: [
      {
        label: "MRR",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(255,82,0,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [],
      },
    ],
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })

    var customers = parseInt(this.state.customers || 100),
      newCustomers = parseInt(this.state.newCustomers || 25),
      churn = parseFloat(this.state.churn || 5).toFixed(2),
      arpa = parseInt(this.state.arpa || 200),
      startRevenue = Math.round(customers * arpa),
      churnedCustomers = 0,
      customerTotal = customers,
      mrr = 0,
      revenueArray = [],
      maxArray = [],
      months = [],
      growthDuration = 1

    this.state.maxCustomers = Math.round(newCustomers / (churn / 100))
    this.state.maxRevenue = Math.round(this.state.maxCustomers * arpa)
    this.state.customerWall = Math.round(this.state.maxCustomers * 0.75)
    this.state.wallMonth = 0

    while (churnedCustomers < newCustomers && growthDuration < 300) {
      customerTotal = customerTotal + newCustomers
      churnedCustomers = Math.round(customerTotal * (churn / 100))
      customerTotal = customerTotal - churnedCustomers

      mrr = customerTotal * arpa

      revenueArray.push(mrr)
      maxArray.push(this.state.maxRevenue)
      months.push(growthDuration)

      if (
        customerTotal >= this.state.customerWall &&
        this.state.wallMonth == 0
      ) {
        this.state.wallMonth = growthDuration
      }

      growthDuration++
    }
    console.log(revenueArray)
    this.state.labels = months
    this.state.datasets[0].data = revenueArray

    console.log("Max Customers " + this.state.maxCustomers)
    console.log("Max revenue: $" + this.state.maxRevenue)
    console.log("Wall end date: " + this.state.wallMonth)
    console.log("Customer Wall: " + growthDuration)
    this.state.customerWall = growthDuration

    this.state.growthEndDate = DateTime.now()
      .plus({ months: growthDuration })
      .toFormat("LLL yyyy")

    console.log("Growth end date: " + this.state.growthEndDate)

    this.state.growthWallDate = DateTime.now()
      .plus({ months: this.state.wallMonth })
      .toFormat("LLL yyyy")

    console.log("Growth wall date: " + this.state.growthWallDate)
  }

  render() {
    return (
      <Layout>
        <SEO title="SaaS Growth Ceiling Calculator" />
        <PageTitle
          preheader="SaaS Calculator"
          title="When Will Your SaaS Business Die?"
          subtitle="Use the free tool below to find out how far your SaaS business has to go..."
        ></PageTitle>
        <div class="px-6 py-6 bg-oOrange rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 lg:w-2/3 m-auto">
          <form class="text-center" id="">
            <h2 class="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
              Calculate your growth ceiling
            </h2>
            <ul class="text-white py-16">
              <li class="mb-8">
                <label class="font-bold" for="customers">
                  How many customers do you have?
                </label>

                <div class="growth_ceiling__form-inputs">
                  <input
                    class="w-full"
                    max="2000"
                    min="0"
                    step="10"
                    type="range"
                    name="customers"
                    value={this.state.customers}
                    onChange={this.handleInputChange}
                  />
                  <div>I've got {this.state.customers} customers.</div>
                </div>
              </li>
              <li class="mb-8">
                <label class="font-bold" for="newCustomers">
                  How many new customers do you get a month?
                </label>
                <div class="growth_ceiling__form-inputs">
                  <input
                    class="w-full"
                    max="500"
                    min="0"
                    step="5"
                    type="range"
                    name="newCustomers"
                    value={this.state.newCustomers}
                    onChange={this.handleInputChange}
                  />
                  <div>
                    I get {this.state.newCustomers} new customers every month.
                  </div>
                </div>
              </li>
              <li class="mb-8">
                <label class="font-bold" for="churn">
                  Monthly churn rate (%)
                </label>
                <div class="growth_ceiling__form-inputs">
                  <input
                    class="w-full"
                    name="churn"
                    max="25"
                    min="0.5"
                    step="0.5"
                    type="range"
                    value={this.state.churn}
                    onChange={this.handleInputChange}
                  />
                  <div>My monthly churn is {this.state.churn}%.</div>
                </div>
              </li>
              <li class="mb-8">
                <label class="font-bold" for="arpa">
                  Monthly revenue per account
                </label>
                <div class="growth_ceiling__form-inputs">
                  <input
                    class="w-full"
                    name="arpa"
                    max="5000"
                    min="0"
                    step="20"
                    type="range"
                    value={this.state.arpa}
                    onChange={this.handleInputChange}
                  />
                  <div>
                    My average monthly revenue per customer is $
                    {this.state.arpa}.
                  </div>
                  <div class="growth_ceiling__calculator-form-field-currencyinput"></div>
                </div>
              </li>
            </ul>
          </form>
        </div>
        <div class="py-16">
          <h2 class="text-2xl font-extrabold tracking-tight text-oOrange sm:text-3xl text-center">
            Your growth ceiling
          </h2>
          <Line
            class="py-8"
            data={this.state}
            options={{
              title: {
                display: true,
                text: "MRR Growth Extrapolation",
                fontSize: 20,
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "MRR ($)",
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Months From Today",
                    },
                  },
                ],
              },
            }}
          />
          <h3 class="text-center font-bold pt-8">
            Explaining Your Growth Ceiling
          </h3>
          <ul class="my-16 text-center">
            <li>
              <p>
                Your business will hit its growth wall in
                <span class="b" id="growth-wall-date">
                  {" " + this.state.growthWallDate + ", "}
                </span>
                roughly {this.state.wallMonth} months from now.
              </p>
              <p class="i">
                At the growth wall, growth will slow dramatically, as you’ll
                have reached 75% of possible growth at your current growth and
                churn rates.
              </p>
            </li>
            <li>
              <p>
                Your business will hit its growth ceiling in
                <span class="b" id="growth-end-date">
                  {" " + this.state.growthEndDate + ", "}
                </span>
                which is
                <span class="b" id="months-of-growth">
                  {" " + this.state.customerWall + " "}
                </span>
                months from today.
              </p>
              <p class="i">
                At the growth ceiling, you won’t be able to grow any more.
              </p>
            </li>
            <li>
              <p>
                Your business won’t grow past
                <span class="b" id="max-customers">
                  {" " + this.state.maxCustomers + " "}
                </span>
                customers and
                <span class="b" id="max-mrr">
                  {" $" + this.state.maxRevenue + " "}
                </span>
                in recurring revenue.
              </p>
              <p class="i">(At your current growth and churn rates.)</p>
            </li>
          </ul>
        </div>

        <section class="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
          <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <svg
              class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
              width="404"
              height="404"
              fill="none"
              viewBox="0 0 404 404"
              role="img"
              aria-labelledby="svg-workcation"
            >
              <title id="svg-workcation">Nickelled</title>
              <defs>
                <pattern
                  id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="404"
                fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
              />
            </svg>

            <div class="relative">
              <blockquote class="mt-10">
                <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                  <p>Why does the growth ceiling occur?</p>
                  <p>
                    As the total customer base of a SaaS business grows, a fixed
                    percentage churn represents a larger and larger number of
                    customers leaving every month. E.g. with a customer base of
                    1000, 5 percent is 50 customers, but at 10,000, 5 percent is
                    500 customers.
                  </p>
                  <p>
                    For most SaaS businesses, that monthly churn rate number
                    will eventually equal the number of customers joining,
                    making it a deadly point in the SaaS lifecycle.
                  </p>
                  <p>
                    Approaching the growth ceiling, the growth of the SaaS
                    business will slow. Assuming no corrective action is taken,
                    it will eventually stop altogether.
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="md:flex md:items-center md:justify-center">
                    <div class="md:flex-shrink-0">
                    <StaticImage
                          src="../../images/david.png"
                          class="mx-auto h-10 w-10 rounded-full"
                          alt="David Batey"
                        />
                    </div>
                    <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div class="text-base font-medium text-gray-900">
                        David Batey
                      </div>

                      <svg
                        class="hidden md:block mx-1 h-5 w-5 text-oOrange"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div class="text-base font-medium text-gray-500">
                        CEO, Nickelled
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
